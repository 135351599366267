import * as i from 'types';
import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { PageTransition } from 'common/layout/PageTransition';
import { useAuthenticationActions } from 'services/hooks';
import { ArrowContainer } from 'modules/login';

import { RequestForm, RequestSuccess } from './components';

export const RequestResetForm = () => {
  const { navigate } = useI18next();
  const { requestResetPassword } = useAuthenticationActions();
  const [isSent, setIsSent] = React.useState(false);

  const handleRequestPassword = (data: i.RequestNewPasswordFormData) => {
    const { emailaddress } = data;

    requestResetPassword(emailaddress)
      .catch(() => {
        //zingfit returns a 404 when the emailaddress is not known
        //therefore errors need to be catched and always show the next screen with finally
      })
      .finally(() => {
        setIsSent(true);
      });
  };

  return (
    <>
      <ArrowContainer onClick={() => navigate('/login')} />
      <PageTransition>
        {!isSent ? (
          <RequestForm handleRequestPassword={handleRequestPassword} />
        ) : (
          <RequestSuccess isSent={isSent} />
        )}
      </PageTransition>
    </>
  );
};
