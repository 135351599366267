import styled from 'styled-components';

import { media } from 'styles/utils';

export const HeaderContainer = styled.div`
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-self: start;
  width: 90%;
  margin: 0 auto;

  ${media.desktop`
    width: 60%;
    align-self: end;
  `}
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  gap: 25px;
  width: 100%;
  margin: 0 auto;

  ${media.desktop`
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr auto;
    height: 80vh;
  `}
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 24px 0;
  margin: 0 auto;
  justify-content: space-between;

  ${media.desktop`
    width: 60%;
    padding: 0;
  `}
`;

export const ButtonContainer = styled.div`
  padding-bottom: 30px;
`;
