import styled from 'styled-components';
import { media } from 'styles/utils';

import { Heading } from 'common/typography';
import { Button } from 'common/interaction';

export const StyledHeading = styled(Heading)`
  width: 90%;
  margin: 24px  auto;

  ${media.desktop`
    width: 60%;
    align-self: end;
    padding-left: 15px;
  `}
`;

export const StyledButton = styled(Button)`
  align-self: end;
  margin: 0 auto;
  padding: 0 24px 0;
  margin: 0 24px;
  width: unset;

  ${media.desktop`
    width: 55%;
    margin: 0 auto;
  `}
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 100px);

  ${media.desktop`
    height: 85vh;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr auto;
    padding-bottom: 30px;
  `}
`;
